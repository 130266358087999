import req from "../methods/req"
import method from '../methods/method'

// 获取客户列表
export const getCateList = function (data){
    return method.get_list({
        url:'/manage/crm.help/cateList',
        ...data
    })
}

export const cateList = function (data){
	return req({
		url:'/manage/crm.help/catesList',
		...data,
	})
}

export const changeCate = function (data){
    return req({
        url:'/manage/crm.help/changeCate',
        ...data
    })
}


export const getCate = function (data){
    return req({
        url:'/manage/crm.help/getCate',
        ...data
    })
}

export const delCate = function (data){
    return req({
        url:'/manage/crm.help/deleteCate',
        ...data
    })
}


export const getHelpList = function (data){
    return method.get_list({
        url:'/manage/crm.help/helpList',
        ...data
    })
}

export const changeHelp = function (data){
    return req({
        url:'/manage/crm.help/changeHelp',
        ...data
    })
}


export const getHelp = function (data){
    return req({
        url:'/manage/crm.help/getHelp',
        ...data
    })
}

export const delHelp = function (data){
    return req({
        url:'/manage/crm.help/deleteHelp',
        ...data
    })
}


