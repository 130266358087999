<template>
    <div>
        <TableList
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['operation','status']"
            :form_data_seo="form_data_seo"
            ref="list"
        >
            <template slot="left_btn">
                <a-button class="float_left" v-auth="'change'" @click="add()" type="primary">添加帮助</a-button>
            </template>
            <template slot="status" slot-scope="data">
                <a-tag
                    :color="data.text == 0 ? config.color.error : config.color.primary"
                    v-for="item in status_list"
                    :key="item.key"
                    v-show="item.key == data.text"
                >{{item.value}}</a-tag>
            </template>

            <template slot="operation" slot-scope="data">
                <a v-auth="'change'" @click="edit(data.record)">编辑</a>
                <a-divider type="vertical" />

				<a-popconfirm title="您确定要删除吗?" v-auth="'delete'" @confirm="del(data.record)">
					<a>删除</a>
				</a-popconfirm>
            </template>
        </TableList>

        <EditPop
            ref="edit_pop"
            :width="1000"
            @handleSubmit="submit"
            :form_data="form_data"
            :visible.sync="visible"
        ></EditPop>
    </div>
</template>

<script>
// @ is an alias to /src
import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import { getHelpList, changeHelp, getHelp, delHelp, cateList } from "@/api/help";
import TableList from "@/components/TableList";

const columns = [
    {
        title: "ID",
        dataIndex: "id",
    },
    {
        title: "排序",
        dataIndex: "sort",
    },
    {
        title: "帮助分类",
        dataIndex: "cate",
    },
    {
        title: "帮助标题",
        dataIndex: "title",
    },
    {
        title: "状态",
        dataIndex: "status",
        scopedSlots: {
            customRender: "status"
        }
    },
    {
        title: "创建时间",
        dataIndex: "create_time",
    },
    {
        title: "操作",
        width: 150,
        scopedSlots: {
            customRender: "operation"
        }
    },
];


export default {
    name: "Index",
    components: {
        EditPop,
        TableList,
        Form
    },
    data() {
        return {
			get_table_list: getHelpList,
			config:this.$config,
            status_list: this.$config.status_list,
            columns,
            visible: false,
            form_data_seo: {
                ...this.$config.form_data_seo,
                list: [
                    {
                        type: "select",
                        name: "cate_id",
                        title: "帮助分类",
                        placeholder: '',
                        options: {

                        },
                        list: []
                    },
                    {
                        type: "text",
                        name: "title",
                        title: "帮助标题",
                        options: {},
                    },
                    {
                        type: "select",
                        name: "status",
                        title: "状态",
                        placeholder: '',
                        options: {

                        },
                        list: this.$config.status_list
                    },
                ],
            },
            form_data: {
                title: '添加',
                list: [
                    {
                        type: 'text',
                        name: 'id',
                        hidden: true,
                        options: {},
                    },
                    {
                        type: 'select',
                        name: 'cate_id',
                        title: '帮助分类',
                        options: {},
                        list: [],
                    },
                    {
                        type: 'text',
                        name: 'title',
                        title: '帮助标题',
                        options: {},
                    },
                    {
                        type: 'text',
                        name: 'sort',
                        title: '排序',
                        options: {},
					},
					{
                        type: "radio",
                        name: "status",
                        title: "状态",
                        placeholder: '',
                        options: {

                        },
                        list: this.$config.status_list
                    },
                    {
                        type: 'editor',
                        name: 'content',
                        title: '内容',
                        options: {},
                    },
                ]
            }
        };
    },


    async created() {
        // this.get_list();

        this.get_cate_list();
    },
    methods: {
        add() {
			this.form_data.title = "添加"
            this.form_data.list.forEach(item => {
                item.options.initialValue = "";
                item.content = "";
            })

            this.visible = true;
        },
        edit(record) {
			this.form_data.title = "编辑"
            getHelp({
                data: {
                    id: record.id
                }
            }).then(res => {
                let data = res.data
                this.form_data.list.forEach(item => {
                    item.options.initialValue = data[item.name];
                })
                this.visible = true;
                this.form_data.list.forEach((item, index) => {
                    if (item.type == 'editor') {
                        this.form_data.list[index].content = data[item.name]
                    }
                    this.$nextTick(res => {
                        if (item.type == 'select') {
                            let val = {};
                            val[item.name] = data[item.name];

                            this.$refs.edit_pop.setFieldsValue(val)
                        }
                    })
                })
            })
        },
        del(record) {
            delHelp({
                data: {
                    id: record.id
                },
                info: true,
            }).then(res => {
                this.$refs.list.get_list();
            })
        },
        async get_cate_list() {
            cateList({

            }).then(res => {
                this.form_data.list.forEach(item => {
                    if (item.name == 'cate_id') {
                        item.list = res.data.list.map(val => {
                            return {
                                key: val.id,
                                value: val.title
                            }
                        });
                    }
                })
            })
        },
        submit(e) {
            let data = e.values;
            if (!data.id) {
                data.id = '';
            }
            changeHelp({
                data: {
                    ...data
                },
                info: true,
            }).then(res => {
                this.visible = false;
                this.$refs.list.get_list();
            })
        },
    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
</style>